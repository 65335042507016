<template lang="pug">
.container.mt-5(fluid fill-height)
  .row.align-center.justify-center.mt-5
    v-col.text-center(sm="auto" min-width="50%")

      v-card.vcenter.elevation-12.pa-5(dark color="secondary")
        .container
          v-form
            v-text-field(
              v-model="email"
              label="Email"
              name="Email"
              prepend-icon="fa-user" type="text")
            v-text-field(
              v-model="password"
              id="password"
              label="Password"
              name="password"
              prepend-icon="fa-key"
              type="password")
        v-card-actions.justify-center
          v-btn(@click="authenticate" :loading="loading" color="terciary") Login
      .row.text-center
        .col.text-center
          v-img.super(:src="require('../assets/ciclogo.png')", width="180")
</template>
<script>
// import LoginForm from '#/LoginForm'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'LoginForm',
  // mixins: [LoginForm],
  computed: {
    ...mapState('app', ['logoh']),
    projectName () {
      return this.$store.state.app.name
    }
  },
  data () {
    return {
      loading: false,
      email: '',
      password: ''
    }
  },
  mounted () {
    this.tokenAuth()
  },
  methods: {
    async tokenAuth () {
      console.log(this.$route.query)
      const self = this
      let token = this.$route.query.token
      if (token) {
        console.log('Hola')
        self.$alert('reconecting', 'Ingresando')
        const AUTH = await this.$firebase.auth().signInWithCustomToken(token)
          .then(user => {
            let game = this.$route.query.game
            self.loading = false
            self.$alert('success', 'Ingresando')
            if (game) {
              self.$router.push({ name: 'results', params: { id: game } })
            } else {
              self.$router.push({ name: 'main' })
            }
          })
          .catch(error => {
            let errorCode = error.code
            let errorMessage = error.message
            if (errorCode === 'auth/invalid-custom-token') this.$alert('danger', 'Error en token de autenticación')
            else self.$alert('danger', 'Error desconocido', errorMessage)
          })
        window.res = AUTH
      }
    },
    async authenticate () {
      const self = this
      this.loading = true
      const AUTH = this.$firebase.auth()
      await AUTH
        .setPersistence(this.$firebase.auth.Auth.Persistence.SESSION).then(() => {
          AUTH.signInWithEmailAndPassword(this.email, this.password)
            .then(succes => {
              self.loading = false
              self.$alert('success', 'Ingresando')
              self.$router.push({ name: 'main' })
            })
            .catch(error => {
              self.loading = false
              if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                self.$alert('danger', 'Error', 'Nombre de usuario o contraseña incorrectos')
              }
              self.loading = false
              console.log(error)
            })
        }).catch(e => console.error(e))
    }
  }
}
</script>
<style>
.super
    {
      align-self:auto;
      /* bottom: 80px; */
      margin: auto;
      top: -350px;
      /* position: absolute; */
    }
.vcenter{
      margin-top: 15vh;
    }
</style>
